export let env = 'development'
if (location.href.indexOf('staging') != -1) {
  env = 'staging'
} else if (location.href.indexOf('mid-works.com') != -1) {
  env = 'production'
} else {
  env = 'development'
}

const PRODUCTION = {
  API: {
    LP_HOST: 'https://lp.mid-works.com/',
    SERVICE_SITE_HOST: 'https://mid-works.com',
    MY_PAGE_HOST: 'https://freelance.mid-works.com'
  },
  RECAPTCHA: {
    SITE_KEY: '6Lenz7IiAAAAAMmNEzePVO5XnRGDbxC9T-wFaihE'
  },
}

const STAGING = {
  API: {
    LP_HOST: 'https://staging-lp.mid-works.com/',
    SERVICE_SITE_HOST: 'https://staging.mid-works.com',
    MY_PAGE_HOST: 'https://staging-freelance.mid-works.com'
  },
  RECAPTCHA: {
    SITE_KEY: '6Lci0LIiAAAAAOIf6fk9yk9ZUO5B-9eEcZuOy27S'
  },
}

const DEVELOPMENT = {
  API: {
    LP_HOST: '',
    SERVICE_SITE_HOST: 'http://localhost:4100',
    MY_PAGE_HOST: 'http://localhost:4105'
  },
  RECAPTCHA: {
    SITE_KEY: '6LeI0rIiAAAAAHnLmkwBtdlFN3p0TJ2KM1CYZV0D'
  },
}

export let CONST = DEVELOPMENT
if (env == 'production') {
  CONST = PRODUCTION
} else if (env == 'staging') {
  CONST = STAGING
} else {
  CONST = DEVELOPMENT
}
